import { useEffect, Suspense, lazy } from "react";
import Footer from "../../Common/Footer/Footer";
import Advertize from "../../Components/Advertise/Advertize";
import { Highlights } from "../../Components/Highlights/Highlights";
import "./Home.css";
import NewMockBanner from "../../Components/MockPortfolio/NewMockBanner";
const FeaturedArticles = lazy(() =>
  import("../../Components/FeaturesArticles/FeaturedArticles")
);
const LatestNews = lazy(() => import("../../Components/LatestNews/LatestNews"));
const MockPortfolio = lazy(() =>
  import("../../Components/MockPortfolio/MockPortfolio")
);
const TableMarket = lazy(() =>
  import("../../Components/TableMarketCap/TableMarket")
);

const Home = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {/* <Advertize /> */}
      <Suspense>
        {/* <MockPortfolio /> */}
        <NewMockBanner />
      </Suspense>
      <div className="container">
        <Highlights />
        <Suspense>
          <TableMarket />
        </Suspense>
        <Suspense>
          <LatestNews />
        </Suspense>
        <Suspense>
          <FeaturedArticles />
        </Suspense>
      </div>
      {/* <Advertize /> */}
    </>
  );
};

export default Home;
