import { NavLink, useNavigate } from "react-router-dom";
import Searchbar from "../Common/SearchBar/Searchbar";

const AdminNavbar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/admin");
  };

  return (
    <div className="navbar-bg inter-font-500">
      <nav className="navbar navbar-expand-lg ">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img
              src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1729594248/IONE_1_gclp2h.png"
              alt="logo"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav m-auto mb-2 mb-lg-0">
              <li className="nav-item ">
                <NavLink className="nav-link" to="/adminDashboard">
                  Dashboard
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/admin/coin">
                  Coin
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink className="nav-link" to="/admin/user">
                  User
                </NavLink>
              </li> */}
              <li className="nav-item">
                <NavLink className="nav-link" to="/admin/blogs">
                  Blogs
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink className="nav-link" to="/admin/news">
                  News
                </NavLink>
              </li>
            </ul>
            {/* <form className="d-flex search-input me-2" role="search">
              <Searchbar />
            </form> */}
            <div className="btn btn-danger pl-2" onClick={handleLogout}>
              <i className="fa fa-sign-out" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default AdminNavbar;
