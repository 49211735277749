import { useEffect, useState } from "react";

import * as API from "../../Utils/Services/api";
import { useForm } from "react-hook-form";

import "./Login.css";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "../../Assets/img/rwa-logo-footer.png";

const SignUp = () => {
  const navigate = useNavigate();

  const [inputValue, setInputValue] = useState({});
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);

  useEffect(() => {
    if (localStorage.getItem("token") && localStorage.getItem("name"))
      navigate("/");
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  const onSubmit = async (data) => {
    try {
      const resp = await API.UserSignUp(data);
      if (resp.data.status) {
        toast.success(`${resp.data.message}`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (err) {
      toast.error(`${err.response.data.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const password = watch("password");

  return (
    <div className="container-fluid" style={{ padding: 0 }}>
      <div className="login-pg create-account-page">
        <div className="navbar-brand">
          <a className="header-logo-main-login-page" href="/">
            <img src={Logo} alt="logo" style={{ width: "120px" }} />
          </a>
        </div>
        {/* <a className="navbar-brand" href="/">
          RWA CAMP
        </a> */}
        <div className="container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="login-form login-form-create-acc">
              <h4>CREATE ACCOUNT</h4>
              <div className="login-form-details mt-4">
                <div className="contact-info">
                  <div className="email-credentials">
                    <p className="pb-2">EMAIL</p>
                    <input
                      type="text"
                      name="email"
                      placeholder="Email Address"
                      onChange={inputHandler}
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          message: "Enter a valid email address",
                        },
                      })}
                    />{" "}
                    {errors.email && (
                      <p style={{ color: "red" }}>{errors.email.message}</p>
                    )}
                  </div>{" "}
                </div>

                <div className="contact-info">
                  <div className="email-credentials">
                    <p className="pb-2">USERNAME</p>
                    <input
                      type="text"
                      name="userName"
                      placeholder="User Name"
                      onChange={inputHandler}
                      {...register("userName", {
                        required: "UserName is required",
                        minLength: {
                          value: 3,
                          message:
                            "Username must be at least 3 characters long",
                        },
                      })}
                    />{" "}
                    {errors.userName && (
                      <p style={{ color: "red" }}>{errors.userName.message}</p>
                    )}
                  </div>{" "}
                </div>

                <div className="new-password-setup">
                  <div className="password-credentials">
                    <div className="forgot-password">
                      <p className="pb-2">PASSWORD</p>
                    </div>
                    <div className="input-container">
                      <input
                        type={isPasswordVisible ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        className="input-with-icon"
                        onChange={inputHandler}
                        {...register("password", {
                          required: "Password is required",
                          minLength: {
                            value: 8,
                            message:
                              "Password must be at least 8 characters long",
                          },
                        })}
                      />{" "}
                      {isPasswordVisible ? (
                        <i
                          className="fa-solid fa-eye"
                          onClick={togglePasswordVisibility}
                        ></i>
                      ) : (
                        <i
                          className="fa-solid fa-eye-slash"
                          onClick={togglePasswordVisibility}
                        ></i>
                      )}
                    </div>{" "}
                    {errors.password && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          letterSpacing: "2.4px",
                        }}
                      >
                        {errors.password.message}
                      </p>
                    )}
                  </div>{" "}
                  <div className="password-credentials">
                    <div className="forgot-password">
                      <p className="pb-2">CONFIRM PASSWORD</p>
                    </div>
                    <div className="input-container">
                      <input
                        type={isConfirmPasswordVisible ? "text" : "password"}
                        name="confirmPassword"
                        onChange={inputHandler}
                        placeholder="Confirm Password"
                        className="input-with-icon"
                        {...register("confirmPassword", {
                          required: "Please confirm your password",
                          validate: (value) =>
                            value === password || "Passwords do not match",
                        })}
                      />
                      {isConfirmPasswordVisible ? (
                        <i
                          className="fa-solid fa-eye"
                          onClick={toggleConfirmPasswordVisibility}
                        ></i>
                      ) : (
                        <i
                          className="fa-solid fa-eye-slash"
                          onClick={toggleConfirmPasswordVisibility}
                        ></i>
                      )}
                    </div>{" "}
                    {errors.confirmPassword && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          letterSpacing: "2.4px",
                        }}
                      >
                        {errors.confirmPassword.message}
                      </p>
                    )}
                  </div>
                  <div className="declaration">
                    <input type="checkbox" className="me-2 mt-2" />
                    <p>
                      Please keep me updated by email with the latest RWA news,
                      research findings, reward programs, event updates, token
                      listings and more information from RWA-CAMP.
                    </p>
                  </div>
                  <div className="sign-up-btn">
                    <button type="submit">Register</button>
                  </div>
                  <div className="create-account">
                    Already have an account ?{" "}
                    <NavLink to="/login">Login</NavLink>
                  </div>
                </div>
              </div>{" "}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default SignUp;
